import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageSection = makeShortcode("PageSection");
const Caption = makeShortcode("Caption");
const Text = makeShortcode("Text");
const Mission = makeShortcode("Mission");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageSection left={<Caption heading="What's AuthenTech?" mdxType="Caption" />} right={<>
      <Text as="p" mdxType="Text">
        Yes, there are plenty of "-tech" categories already -- FinTech,
        HealthTech and EdTech, to name a few. And yet, amidst a sea of technical
        business models, our society is arguably more lonely, commoditized and
        disempowered.
      </Text>
      <Text as="p" mdxType="Text">
        People crave a level of authentic connection and they need to come
        together in uplifting, system-changing ways more than ever before. The
        existing paradigms are at best not serving these needs, and in many
        cases exasperating them.
      </Text>
      <Text as="p" mdxType="Text">
        AuthenTech companies prioritize community over audience, intention over
        attention, and value over profit. This category is emerging not because
        the world needs another technical business model, but because the world
        needs inclusive, authentic connections.
      </Text>
      <Text as="p" mdxType="Text">
        It's time for a new way of doing business that centers the human and
        turns customers from commodities to community members.
      </Text>
    </>} mdxType="PageSection" />
    <Mission mdxType="Mission" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      